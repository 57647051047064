.headerUserBar {
	@include container;
	font-size: $font-size-l;
	position: relative;
}
.headerUserBar_auth_false,
.headerUserBar_auth_true {
	@include clearfix;
}

	.headerUserBar__cabinetLinks {
		font-size: 0;
		float: left;
		.headerUserBar_auth_true & {
			width: 65%;
		}
		.headerUserBar_auth_false & {
			// width: 50%;
		}
		
	}

		.headerUserBar__cabinetLink {
			@include links (#a5a7ac, $color-light);
			display: inline-block;
			vertical-align: bottom;
			margin-right: 30px;
			height: 54px;
			line-height: 54px;
			font-size: 16px;
			white-space: nowrap;
			outline: none;
			&:hover{
				text-decoration: none;
			}
			&.isActive {
				border-bottom: 3px solid $color-accent;
				color: $color-accent;
			}
			@include media(phone) {
				font-size: 14px;
				margin-right: 15px;
			}
		}

			.headerUserBar__cabinetLinkCounter {
				display: inline-block;
				width: 30px;
				height: 25px;
				border-radius: 12px;
				background-color: $color-accent;
				color: $color-light;
				line-height: 24px;
				text-align: center;
				vertical-align: baseline;
			}

	.headerUserBar__auth {
		text-align: right;
		.headerUserBar_auth_true & {
			float: right;
			width: 35%;
		}
		.headerUserBar_auth_false & {
			@include clearfix;
		}
		@include media(phone) {
			font-size: 14px;
		}
	}

		.headerUserBar__authLink {
			color: #fff;
			line-height: 54px;
			white-space: nowrap;
			opacity: 0.5;
			&:hover{
				text-decoration: none;
				color: #fff;
				opacity: 1;
			}
		}

		.headerUserBar__authLink_login {
			margin-right: 40px;
		}

		.headerUserBar__authLink_logout {
			position: relative;
			padding-right: 28px;
			
			&:after {
				content: '';
				position: absolute;
				right: 0;
				top: calc(50% - 8px);
				width: 18px;
				height: 18px;
				background-image: url(/staticcontent/img/icon/log.png);
				background-size: 18px;
				background-position: 50% 50%;
			}
			@include media(phone) {
				font-size: 0;
			}
		}

		.headerUserBar__authLink_signin {}
	
	
	
	
.headerUserBar__toogle{
	display: none;
	@include media(phone-phablet) {
		display: inline-block;
		vertical-align: bottom;
		font-size: 24px;
		color: #fff;
		line-height: 1;
		padding-bottom: 17px;
		&:hover{
			text-decoration: none;
		}
	}
}

.headerUserBar__container{
	display: inline-block;
	vertical-align: middle;
	@include media(phone-phablet) {
		display: none;
		border-top: 1px solid #414550;
		position: absolute;
		top: 54px;
		left: 0;
		right: 0;
		background-color: #4A4D58;
		padding: 0 10px;
		z-index: 4;
	}
}