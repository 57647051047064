.header {
	background-color: #414550;
}

	.header__bar {
		background-color: #4a4d58;
	}

	.header__content {
		@include clearfix;
		@include container;
		padding-top: 19px;
		padding-bottom: 17px;
	}
	.headerCountry{
		float: right;
		font-size: 0;
		text-align: right;
		margin-top: 3px;
		@include media(phone-smartphone) {
			width: 100%;
			text-align: left;
		}
	}
	.headerCountry__label{
		display: inline-block;
		vertical-align: middle;
		color: #a5a7ac;
		margin-right: 15px;
		font-size: 16px;
		@include media(phone) {
			font-size: 14px;
		}
	}
	.header .headerCountry__select{
		@include appearance;
		display: inline-block;
		vertical-align: middle;
		width: 255px;
		margin: 8px 0;
		border-radius: 0;
		background-color: #676a73;
		.select{
			border: 1px solid #676a73;
			color: #fff;
			height: 35px;
		}
		&:after{
			height: 35px;
			background-image: url(/staticcontent/img/icon/arrow-white-smal-down.png);
		}
		option{
			color: #000;
			padding: 5px 15px;
		}
		@include media(phone-smartphone) {
			width: 100%;
		}
	}
	
$header-logo-width: 140px;
$header-dropmenu-width: 86px;

@import "elm/userbar";
@import "elm/logo";
@import "elm/dropmenu";
@import "elm/search";
