.promoBlocks{
	@include clearfix;
	overflow: hidden;
	font-size: 0;
}
.promoBlock{
	display: block;
	overflow: hidden;
	position: relative;
	&:hover{
		cursor: pointer;
		.promoBlock__pic{
			transform: scale(1.1);
			&:before{
				opacity: .3;
			}
		}
	}
}
.promoBlock__title{
	font-size: 18px;
	font-weight: 200;
	position: absolute;
	z-index: 1;
	top: 50%;
	transform: translateY(-50%);
	color: #fff;
}
.promoBlocks .promoBlock{
	.promoBlock__title{
		@include media(phone-smartphone) {
			left: auto;
			right: auto;
			width: 100%;
			text-align: center;
			transform: none;
			padding: 0 15px;
			white-space: normal;
		}
	}
}
.promoBlock__pic{
	width: 100%;
	height: 100%;
	background-color: #000;
	background-position: center;
	background-size: cover;
	transition: all .4s ease;
	&:before{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: #000;
		opacity: .5;
		transition: all .2s ease;
	}
}

.promoBlock-1{
	margin-bottom: 15px;
	width: 100%;
	height: 510px;
	background-color: #E0DFDD;
	.promoBlock__title{
		font-size: 48px;
		left: 6%;
	}
	@include media(phone-smartphone) {
		.promoBlock__title{
			left: 0;
		}
	}
}
.promoBlock-2{
	float: left;
	width: calc(76% - 15px);
	height: 250px;
	background-color: #F8F8FA;
	.promoBlock__title{
		right: 10%;
		font-size: 30px;
	}
	@include media(phone-tablet) {
		width: 100%;
		float: none;
		margin-bottom: 15px;
	}
	@include media(phone-smartphone) {
		.promoBlock__title{
			top: auto;
			bottom: 20px;
		}
	}
	
}
.promoBlock-3{
	top: 15px;
	margin-right: 15px;
	float: left;
	width: calc(38% - 15px);
	height: 250px;
	background-color: #F2F2F2;
	.promoBlock__title{
		left: 8%;
	}
	@include media(phone-tablet) {
		top: 0;
		width: calc(70% - 15px);
		float: none;
	}
	@include media(phone-phablet) {
		width: calc(60% - 15px);
	}
	@include media(phone-smartphone) {
		width: 100%;
		height: 330px;
		.promoBlock__title{
			top: 10%;
		}
	}
}
.promoBlock-4{
	top: 15px;
	float: left;
	width: calc(38% - 15px);
	height: 250px;
	background-color: #EEEEF0;
	.promoBlock__title{
		left: 8%;
	}
	@include media(phone-tablet) {
		top: 0;
		margin-top: 15px;
		width: calc(70% - 15px);
		float: none;
	}
	@include media(phone-phablet) {
		width: calc(60% - 15px);
	}
	@include media(phone-smartphone) {
		width: 100%;
		height: 330px;
		.promoBlock__title{
			top: 5%;
		}
	}
}
.promoBlock-5{
	float: right;
	width: 24%;
	height: 515px;
	background-color: #E8E8E8;
	.promoBlock__title{
		top: 10%;
		right: 50%;
		transform: translateX(50%);
		white-space: nowrap;
	}
	@include media(phone-tablet) {
		width: 30%;
	}
	@include media(phone-phablet) {
		width: 40%;
	}
	@include media(phone-smartphone) {
		width: 100%;
		margin-bottom: 15px;
		.promoBlock__title{
			font-size: 30px;
		}
	}
}
