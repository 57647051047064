.icon{
	display: inline-block;
	vertical-align: middle;
} 
.icon--paypal{
	background-image: url(/staticcontent/img/icon/paypal.png);
	width: 54px;
	height: 14px;
}
.icon--bitcoin{
	background-image: url(/staticcontent/img/icon/bitcoin.png);
	width: 56px;
	height: 13px;
}
