.js-popover{
	position: relative;
	display: inline-block;
}
.js-popover__container{
	position: absolute;
	display: none;
	z-index: 10;
	background: #fff;
	box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
	padding: 20px;
}
.popover__container{
	left: 50%;
	transform: translateX(-50%);
	&:before{
		content: "";
		display: inline-block;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
	}
}
.popoverBottom{
	.popover__container{
		top: 100%;
		&:before{
			border-bottom: 8px solid #fff;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			top: -8px;
			
		}
	}
}
.popoverTop{
	.popover__container{
		bottom: 100%;
		box-shadow: 0 0px 30px rgba(0, 0, 0, 0.2);
		&:before{
			border-top: 8px solid #fff;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			bottom: -8px;
		}
	}
}

.form{
	.popoverTop{
		vertical-align: baseline;
		.input{
			width: 58px;
			text-align: right;
			font-size: 16px;
			vertical-align: bottom;
			margin-right: 10px;
		}
		.btn{
			vertical-align: bottom;
			font-size: 16px;
			padding: 13px 13px;
		}
	}
	.popover__toggle{
		text-decoration: none;
		margin-left: 10px;
	}
	.popover__title{
		margin-bottom: 10px;
	}
	.popover__container{
		width: 195px;
		bottom: 135%;
	}
}