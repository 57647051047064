.profile{}
.profile__header{}
.profile__pic{}
.profile__img{
	max-width: 100%;
}
.profile__controls{
	margin-bottom: 50px;
}
.profile__control{
	margin-top: 10px;
}
.profile__description{
	p{
		margin-top: 0;
		margin-bottom: 15px;
	}
}