.userBlock{
	position: relative;
	display: inline-block;
	vertical-align: middle;
	text-align: left;
	padding-left: 50px;
	&:hover{
		text-decoration: none;
		.userBlock__name{
			text-decoration: underline;
		}
	}
}
.userBlock__pic{
	position: absolute;
	top: 0;
	left: 0;
	display: inline-block;
	vertical-align: middle;
	height: 41px;
	width: 41px;
}
.userBlock__img{
	width: 100%;
	max-height: 100px;
	vertical-align: middle;
	border-radius: 50%;
}
.userBlock__content{
	display: inline-block;
	vertical-align: middle;
	font-size: 14px;
}
.userBlock__name{
	color: $color-accent;
}
.userBlock__data{
	color: $color-text;
}

.userBlock--big{
	padding-left: 0;
	&:hover{
		.userBlock__name{
			text-decoration: none;
		}
	}
	.userBlock__name,
	.userBlock__status{
		display: inline-block;
		vertical-align: bottom;
	}
	.userBlock__name{
		margin-bottom: 0;
		margin-right: 10px;
		color: $color-brand;
	}
	.userBlock__status{
		margin-bottom: 3px;
		font-size: 12px;
		&:before{
			content: "";
			display: inline-block;
			vertical-align: middle;
			margin-right: 5px;
			margin-bottom: 2px;
			width: 6px;
			height: 6px;
			background-size: 6px;
			background-position: 50% 50%;
		}
	}
	.online{
		color: #8bc34a;
		&:before{
			background-image: url(/staticcontent/img/icon/online.png);
		}
	}
	.offline{
		color: #acadaf;
		&:before{
			background-image: url(/staticcontent/img/icon/offline.png);
		}
	}
	.userBlock__data{
		margin-top: 10px;
	}
}


.userBlock--chat{
	width: 100%;
	padding-right: 15px;
	&:hover .userBlock__name{
		text-decoration: none;
	}
	.userBlock__pic{
		top: 5px;
	}
	.userBlock__indicator{
		position: absolute;
		top: -10px;
		left: -10px;
		width: 25px;
		height: 25px;
		line-height: 25px;
		font-size: 12px;
		text-align: center;
		color: #fff;
		background-color: $color-accent;
		border-radius: 25px;
	}
	.userBlock__title{
		display: inline-block;
		&:hover{
			.userBlock__name{
				color: $color-accent-dark;
			}
		}
	}
	.userBlock__text{
		margin-top: 5px;
	}
	.userBlock__message{
		position: relative;
		margin-bottom: 20px;
		&:last-child{
			margin-bottom: 0;
		}
	}
	.userBlock__date{
		position: absolute;
		top: -24px;
		right: 0;
		font-style: italic;
		color: $color-minor;
		font-size: 12px;
	}
}
.messagesList{
	@include media(phone) {
		.userBlock{
			padding-left: 0;
		}
		.userBlock__pic{
			display: none;
		}
	}
}