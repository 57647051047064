@font-face {
    font-family: 'Open Sans';
    src: url('/staticcontent/fonts/opensans-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/staticcontent/fonts/opensans-semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('/staticcontent/fonts/opensans-light.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}
