$header-dropmenu-gap: 40px;

.headerDropMenu {
	position: relative;
	float: left;
	margin: 0 ($header-dropmenu-gap/2);
	width: $header-dropmenu-width - $header-dropmenu-gap;
	height: 50px;
}

	.headerDropMenu__toggle {
		overflow: hidden;
		position: absolute;
		top: calc(50% - 8px);
		left: calc(50% - 10px);
		width: 21px;
		height: 16px;
		border-top: 2px solid;
		border-right: none;
		border-bottom: 2px solid;
		border-left: none;
		border-color: $color-light;
		background-color: transparent;
		text-indent: -9999px;
		cursor: pointer;
		outline: none;
		&:before {
			content: '';
			position: absolute;
			top: calc(50% - 1px);
			left: 0;
			width: 100%;
			height: 2px;
			background-color: $color-light;
		}
		&:hover{
			border-color: $color-accent;
			&:before{
				background-color: $color-accent;
			}
		}
	}

.headerDropMenu .headerDropMenu__container{
	top: calc(100% + 17px);
	width: 282px;
	.popover__item{
		display: block;
		padding: 10px;
	}
	.contentNav__link{
		&:hover{
			text-decoration: none;
			color: $color-text;
		}
	}
	@include media(phone) {
		transform: translateX(-54%);
		left: 0%;
		&:before{
			left: 62%;
		}
	}
}